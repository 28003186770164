<template>
	<div>
		<!-- 用户管理 -->
		<div class="queryBox mb20">
			<el-button size="mini" type="primary" icon="el-icon-plu" @click="addAccount('new')">{{$t('main.systemMenu.newlyAdded')}}</el-button>
		</div>
		<template>
			<table width="900px" class="dataTable" v-loading="loading">
				<thead>
					<tr>
						<th class="textCenter" >{{$t('main.systemMenu.serialNumber')}}</th>
						<th class="textCenter" >{{$t('main.systemMenu.userName')}}</th>
						<th class="textCenter" style="width: 150px;">{{$t('main.systemMenu.lastLoginTime')}}</th>
						<th class="textCenter">{{$t('main.systemMenu.role')}}</th>
						<th class="textCenter" style="width: 500px;">{{$t('main.systemMenu.function')}}</th>
					</tr>
				</thead>
				<tbody :style="`max-height: ${height}px;`">

					<tr class="" v-for="(item,index) in tableData" v-if="tableData && tableData.length > 0"
						:key="index">
						<td class="textCenter" >{{index+1}}</td>
						<td class="textCenter">{{item.username}}</td>
						<td class="textCenter" style="width: 150px;">{{item.login_time}}</td>
						<td class="textCenter">{{item.role_name}}</td>
						<td class=""style="width: 500px;">
							<el-link type="primary" @click="resPassword(item)" class="mr20">{{$t('main.systemMenu.resetUserPassword')}}</el-link>
							<el-link type="primary" @click="setUserGoogleKey(item)" class="mr20">{{$t('main.systemMenu.resetGoogleVerificationCode')}}</el-link>
							<el-link type="primary" @click="delUser(item)" class="mr20">{{$t('main.systemMenu.deleteUser')}}</el-link>
							<el-link type="primary" @click="addAccount('roles',item)" class="mr20">{{$t('main.systemMenu.setRole')}}</el-link>
						</td>
					</tr>
					<tr class="nodata" v-if="!tableData || tableData.length === 0">
						<td colspan="10">{{$t('main.systemMenu.noData')}}</td>
					</tr>
					
				</tbody>
			</table>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="150px" :title="title" :visible.sync="dialogVisible" @close="close":close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<div v-if="titleName" class="mb20">{{titleName}}</div>
					<el-form :label-position="labelPosition" label-width="100px" v-if="type === 'new'">
						<el-form-item label="登录账号">
							<el-input v-model="username" placeholder="请输入登录账号"></el-input>
						</el-form-item>
						<el-form-item label="登录密码">
							<el-input autocomplete="new-password" type="password" v-model="password"
								placeholder="请输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="确认登录密码">
							<el-input autocomplete="new-password" type="password" v-model="passwordSure"
								placeholder="请再次输入登录密码"></el-input>
						</el-form-item>
					</el-form>
					<el-form :label-position="labelPosition" label-width="100px" v-if="type === 'roles'">
						<el-form-item label="用户账号">
							{{item.username}}
						</el-form-item>
						<el-form-item label="角色列表"></el-form-item>
						<div>
							<template>
							  <el-radio-group v-model="roles">
							    <el-radio :label="item.id" v-for="item in rolesList">{{item.name}}</el-radio>
							  </el-radio-group>
							</template>
						</div>
					</el-form>
				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				dialogloading: false,
				dialogVisible: false,
				date: [],
				input: '',
				tableData: [],
				dataTotal: {},
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				item:{},
				//新增
				labelPosition: 'right',
				username:'',
				password:'',
				passwordSure: '', //确认密码
				type: '', //new  新增   roles配置角色
				title: '',
				titleName: '',
				submitText: '新增',
				roles:'',//角色
				rolesList:[],//角色列表
				rolePageSize:100,
				height:0
			}
		},
		created() {
			this.getUsers()
			this.getRoles()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
				console.log(this.height)
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			getUsers() {
				// this.loading = true
				this.$api.getUsers({
					page: this.page,
					size: this.pageSize
				}).then(res => {
					this.loading = false
					if (res.status === 1) {
						this.tableData = res.data.data
						this.total = res.data.total
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					console.log(res)
				}).catch(error => {
					this.loading = false
				})
			},
			//角色列表
			getRoles(){
				this.$api.getRoles({
					size:this.rolePageSize
				}).then(res => {
					if (res.status === 1) {
						this.rolesList = res.data.data						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					console.log(res)
				}).catch(error => {
					this.loading = false
				})
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.getUsers()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.getUsers()
			},
			addAccount(type,item) {
				this.dialogVisible = true
				this.type = type
				if (item) {
					this.item = item;
				}
				if (this.type === 'new') {
					this.title = '新增用户'
					this.submitText = '新增'
				}
				else if(this.type == 'roles'){
					this.roles = this.item.role_id
					this.title = '设置用户角色'
					this.submitText = '确定'
				}
			},
			close() {
				this.dialogVisible = false
				this.type="";
				this.roles="";
				this.username="";
				this.password="";
				this.passwordSure = "";
				this.item = {};
			},
			//新增
			onSubmit() {
				if (this.type === 'new') {
					//新增商家
					if (isEmpty(this.username, '请输入登录账号')) {
						return
					}
					if (isIncludeHanzi(this.username, '用户名不能包含汉字')) {
						return
					}
					if (isEmpty(this.password, '请输入密码')) {
						return
					}
					if (this.password != this.passwordSure) {
						this.$message({
							type: 'error',
							message: '两次输入密码不一致'
						})
						return
					}
					let data = {
						user: this.username,
						password: this.$md5(this.password),
					}
					this.dialogloading = true
					this.$api.addUser(data).then(res => {
						console.log(res)
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.page = 1;
							this.getUsers()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}
				else if(this.type == 'roles'){
					this.setRoles()
				}
			},
			//重置用户密码
			resPassword(item) {
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					let data = {
						// id: item.id,
						user: item.username
					}
					this.loading = true
					this.$api.resetUserPassword(data).then(res => {
						console.log(res)
						this.loading = false
						if (res.status === 1) {
							this.$alert('新密码为:' + res.data.password, '重置密码成功', {
								confirmButtonText: '确定',
							});
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}

					}).catch(error => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消重置用户·密码'
					});
				});

			},
			//重置谷歌验证码
			setUserGoogleKey(item) {
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					this.loading = true;
					let data = {
						user: item.username
					}
					this.$api.setUserGoogleKey(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消重置谷歌验证码`
					});
				});
			},
			//删除用户
			delUser(item){
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					this.loading = true;
					let data = {
						id: item.id,
						user:item.username
					}
					this.$api.delUser(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.getUsers()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消删除用户`
					});
				});
			},
			//设置角色
			setRoles(){
				if(!this.roles || this.roles == ""){
					this.$message({type:"error",message:"请选择角色"})
					return
				}
				let data = {
					manager_id:this.item.id,
					role_id:this.roles
				}
				this.dialogloading = true
				this.$api.configUserRole(data).then(res=>{
					this.dialogloading = false
					if(res.status == 1){
						this.close()
						this.getUsers()
						this.$message({type:"success",message:res.msg})
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.dialogloading = false
				})
			}
		},
		watch: {
			total1(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
					console.log(this.height)
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	/* 滚动条宽度 */
	  ::-webkit-scrollbar {
	       width: 0px;
	   }
	   table tbody {
	       display: block;
	       overflow-y: auto;
	       -webkit-overflow-scrolling: touch;
			border-bottom: solid 1px #ddd;
	   }	 
	   table tbody tr:last-child td{
			border-bottom: none;
	   }	 
	   table thead tr, table tbody tr, table tfoot tr {
	       box-sizing: border-box;
	       table-layout: fixed;
	       display: table;
	       width: 100%;
	   }
	   table td{
		   word-break:break-all;
	   }
</style>
